<template>
  <div class="page">
    <!-- 头部 -->
    <div class="header cursor">
      <img
        src="../assets/imgs/gaokao_pc/p1.jpg"
        mode="widthFix"
        @click="toIndex"
      />
      <img src="../assets/imgs/gaokao_pc/p2.jpg" mode="widthFix" />
      <img src="../assets/imgs/gaokao_pc/p43.jpg" mode="widthFix" />
      <img src="../assets/imgs/gaokao_pc/p3.jpg" mode="widthFix" />
    </div>
    <!-- 中部 -->
    <div class="content">
      <!-- 高考冲刺为梦想而战图片 -->
      <div class="content1">
        <img src="../assets/imgs/gaokao_pc/p23.jpg" mode="widthFix" />
      </div>
      <!-- 立即咨询表单 -->
      <div class="content2">
        <div class="content2-main">
          <div class="content2-left">
            <img src="../assets/imgs/gaokao_pc/p4.png" mode="widthFix" />
          </div>
          <div class="content2-right">
            <div class="content2-right-title">立即免费领取个性化提分方案</div>
            <div class="content2-right-item">
              <span>学生姓名</span>
              <input
                type="span"
                placeholder="请输入学生姓名"
                v-model="form.userName"
                placeholder-class="phcolor"
              />
            </div>
            <div class="content2-right-item">
              <span>文理科</span>
              <div class="options">
                <div
                  class="common"
                  @click="onClickItem(option, index)"
                  :class="{ active: index == currentIndex }"
                  v-for="(option, index) in options"
                  :key="index"
                >
                  {{ option.title }}
                </div>
              </div>
            </div>
            <div class="content2-right-item">
              <span>联系电话</span>
              <input
                type="span"
                placeholder="请输入联系电话"
                v-model="form.userPhone"
                placeholder-class="phcolor"
              />
            </div>
            <div class="content2-right-item">
              <span></span>
              <input
                v-model="form.code"
                type="span"
                placeholder="请输入验证码"
                placeholder-class="phcolor"
              />
              <div
                class="code"
                style="right: 80px"
                @click="is_click && get_code()"
              >
                {{ verify }}
              </div>
            </div>
            <button class="button" @click="submit">立即咨询</button>
            <div class="tip">望子成龙郑重承诺：所有信息严格保密</div>
          </div>
        </div>
      </div>
      <!-- 为什么一定要上大学图片 -->
      <div class="content3">
        <img src="../assets/imgs/gaokao_pc/p47.png" mode="widthFix" />
        <img src="../assets/imgs/gaokao_pc/p48.png" mode="widthFix" />
        <img src="../assets/imgs/gaokao_pc/p49.png" mode="widthFix" />
        <div class="contact" @click="contact"></div>
      </div>
      <!-- 高考为什么只选择望子成龙 -->
      <div class="content4">
        <img src="../assets/imgs/gaokao_pc/p7.jpg" mode="widthFix" />
      </div>
      <!-- 历届学子风采轮播图 -->
      <div class="swiper-container banner content5">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in swipers" :key="item.id">
            <img :src="item.img" />
          </div>
        </div>
      </div>
      <!-- 把握高考命脉 -->
      <div class="content6">
        <img src="../assets/imgs/gaokao_pc/p14.jpg" mode="widthFix" />
        <img src="../assets/imgs/gaokao_pc/p15.png" mode="widthFix" />
        <img src="../assets/imgs/gaokao_pc/p16.png" mode="widthFix" />
      </div>
      <!-- 高分闯关秘籍 -->
      <div class="content7">
        <img src="../assets/imgs/gaokao_pc/p44.png" mode="widthFix" />
        <img src="../assets/imgs/gaokao_pc/p45.jpg" mode="widthFix" />
        <img src="../assets/imgs/gaokao_pc/p46.png" mode="widthFix" />
      </div>
      <!-- 冲刺学习计划 -->
      <!-- <div class="content8">
        <img src="../assets/imgs/gaokao_pc/p18.png" mode="widthFix" />
        <img src="../assets/imgs/gaokao_pc/p19.png" mode="widthFix" />
        <img src="../assets/imgs/gaokao_pc/p20.png" mode="widthFix" />
        <img src="../assets/imgs/gaokao_pc/p21.png" mode="widthFix" />
        <img src="../assets/imgs/gaokao_pc/p22.png" mode="widthFix" />
        <img
          class="img"
          @click="contact"
          src="../assets/imgs/gaokao_pc/p24.png"
          mode="widthFix"
        />
      </div> -->
      <!-- 学生情况反馈 -->
      <!-- <div class="content9">
        <div class="content9-top">
          <img src="../assets/imgs/gaokao_pc/p26.png" mode="widthFix" />
        </div>
        <div class="content9-cen"> -->
          <!-- 左侧按钮 -->
          <!-- <div class="content9-cen-left">
            <img
              src="../assets/imgs/gaokao_pc/p28.png"
              mode="widthFix"
              @click="prev1"
            />
          </div> -->
          <!-- 中部轮播 -->
          <!-- <div class="content9-cen-main">
            <div
              class="content9-cen-main-center"
              :style="
                'margin-left:' + marfinLeft + 'px;transition:' + transition
              "
            >
              <div
                class="content9-cen-main-item"
                v-for="item in list4"
                :key="item.id"
              >
                <img :src="item.img" mode="widthFix" />
              </div>
            </div>
          </div> -->
          <!-- 右侧按钮 -->
          <!-- <div class="content9-cen-right">
            <img
              src="../assets/imgs/gaokao_pc/p27.png"
              mode="widthFix"
              @click="next1"
            />
          </div>
        </div>
        <div class="content9-bot">
          <img
            src="../assets/imgs/gaokao_pc/p24.png"
            mode="widthFix"
            @click="contact"
          />
        </div>
      </div> -->
      <!-- 精选高考教材 -->
      <div class="content10">
        <div class="content10-top">
          <img src="../assets/imgs/gaokao_pc/p34.jpg" mode="widthFix" />
        </div>
        <div class="content10-cen">
          <div class="content10-cen-item" :key="item.id" v-for="item in list3">
            <img :src="item.img" mode="widthFix" />
          </div>
        </div>
        <div class="content10-bot">
          <img src="../assets/imgs/gaokao_pc/p35.png" mode="widthFix" />
        </div>
        <div class="content10-bott">
          <img
            src="../assets/imgs/gaokao_pc/p36.png"
            mode="widthFix"
            @click="contact"
          />
        </div>
      </div>
      <!-- 优秀学员励志奖学金 -->
      <!-- <div class="content11">
        <img src="../assets/imgs/gaokao_pc/p37.jpg" mode="widthFix" />
      </div> -->
      <!-- 高考中心学习环境 -->
      <div class="content12">
        <div class="content12-top">
          <img src="../assets/imgs/gaokao_pc/p41.png" mode="widthFix" />
        </div>
        <div class="content12-cen" @mouseover="over" @mouseout="out">
          <div class="content12-cen-left">
            <img
              src="../assets/imgs/gaokao_pc/p28.png"
              mode="widthFix"
              @click="prev"
            />
          </div>
          <div class="content12-cen-main">
            <div
              class="content12-cen-main-item"
              :style="ulWidth"
              :class="{ tran: noLast }"
            >
              <div
                class="content12-cen-main-itemt"
                v-for="item in list5"
                :key="item.id"
                :style="{ width: liWidth + '%' }"
              >
                <img :src="item.img" mode="widthFix" />
              </div>
            </div>
          </div>
          <div class="content12-cen-right">
            <img
              src="../assets/imgs/gaokao_pc/p27.png"
              mode="widthFix"
              @click="next"
            />
          </div>
        </div>
        <div class="content12-bot">
          <img
            src="../assets/imgs/gaokao_pc/p36.png"
            mode="widthFix"
            @click="contact"
          />
        </div>
      </div>
    </div>
    <!-- 尾部 -->
    <div class="foot">
      <div class="foot-center">
        <div class="foot-left">
          <img src="../assets/imgs/gaokao_pc/p42.png" mode="widthFix" />
        </div>
        <div class="foot-main">
          <div class="foot-main-title">立即免费领取个性化提分方案</div>
          <div class="foot-main-item">
            <span>学生姓名</span>
            <input
              type="span"
              placeholder="请输入学生姓名"
              v-model="form.userName"
              placeholder-class="phcolor"
            />
          </div>
          <div class="foot-main-item">
            <span>文理科</span>
            <div class="options">
              <div
                class="common"
                @click="onClickItem(option, index)"
                :class="{ active: index == currentIndex }"
                v-for="(option, index) in options"
                :key="index"
              >
                {{ option.title }}
              </div>
            </div>
          </div>
          <div class="foot-main-item">
            <span>联系电话</span>
            <input
              type="span"
              placeholder="请输入联系电话"
              v-model="form.userPhone"
              placeholder-class="phcolor"
            />
          </div>
          <div class="foot-main-item">
            <span></span>
            <input
              v-model="form.code"
              type="span"
              placeholder="请输入验证码"
              placeholder-class="phcolor"
            />
            <div class="code" @click="is_click && get_code()">
              {{ verify }}
            </div>
          </div>
          <button class="foot-button" @click="submit">立即咨询</button>
          <div class="tip">望子成龙郑重承诺：所有信息严格保密</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sendSms, submitUserInfo } from "@/api/api.js";
import Swiper from "swiper/dist/js/swiper.js";
export default {
  data() {
    return {
      //选项卡
      options: [
        {
          id: 0,
          title: "文科",
        },
        {
          id: 1,
          title: "理科",
        },
      ],
      currentIndex: -1, //选项卡当前选项
      // 历届学子风采轮播图
      swipers: [
        {
          id: 0,
          img: require("../assets/imgs/gaokao_pc/p8.jpg"),
        },
        {
          id: 1,
          img: require("../assets/imgs/gaokao_pc/p9.jpg"),
        },
        {
          id: 2,
          img: require("../assets/imgs/gaokao_pc/p10.jpg"),
        },
        {
          id: 3,
          img: require("../assets/imgs/gaokao_pc/p11.jpg"),
        },
        {
          id: 4,
          img: require("../assets/imgs/gaokao_pc/p12.jpg"),
        },
      ],

      // 精编高考教材
      list3: [
        {
          id: 0,
          img: require("../assets/imgs/gaokao_pc/shu1.jpg"),
        },
        {
          id: 1,
          img: require("../assets/imgs/gaokao_pc/shu2.jpg"),
        },
        {
          id: 2,
          img: require("../assets/imgs/gaokao_pc/shu3.jpg"),
        },
        {
          id: 3,
          img: require("../assets/imgs/gaokao_pc/shu4.jpg"),
        },
        {
          id: 4,
          img: require("../assets/imgs/gaokao_pc/shu5.jpg"),
        },
        {
          id: 5,
          img: require("../assets/imgs/gaokao_pc/shu6.jpg"),
        },
        {
          id: 6,
          img: require("../assets/imgs/gaokao_pc/shu7.jpg"),
        },
        {
          id: 7,
          img: require("../assets/imgs/gaokao_pc/shu8.jpg"),
        },
      ],
      // 学生情况实时反馈
      // list4: [
      //   {
      //     id: 0,
      //     img: require("../assets/imgs/gaokao_pc/p29.png"),
      //   },
      //   {
      //     id: 1,
      //     img: require("../assets/imgs/gaokao_pc/p30.png"),
      //   },
      //   {
      //     id: 2,
      //     img: require("../assets/imgs/gaokao_pc/p31.png"),
      //   },
      //   {
      //     id: 3,
      //     img: require("../assets/imgs/gaokao_pc/p32.jpg"),
      //   },
      //   {
      //     id: 4,
      //     img: require("../assets/imgs/gaokao_pc/p33.jpg"),
      //   },
      //   {
      //     id: 5,
      //     img: require("../assets/imgs/gaokao_pc/p29.png"),
      //   },
      //   {
      //     id: 6,
      //     img: require("../assets/imgs/gaokao_pc/p30.png"),
      //   },
      //   {
      //     id: 7,
      //     img: require("../assets/imgs/gaokao_pc/p31.png"),
      //   },
      //   {
      //     id: 8,
      //     img: require("../assets/imgs/gaokao_pc/p32.jpg"),
      //   },
      // ],
      //环境轮播图
      list5: [
        {
          id: 0,
          img: require("../assets/imgs/gaokao_pc/h5.jpg"),
        },
        {
          id: 1,
          img: require("../assets/imgs/gaokao_pc/h1.jpg"),
        },
        {
          id: 2,
          img: require("../assets/imgs/gaokao_pc/h2.jpg"),
        },
        {
          id: 3,
          img: require("../assets/imgs/gaokao_pc/h3.jpg"),
        },
        {
          id: 4,
          img: require("../assets/imgs/gaokao_pc/h4.jpg"),
        },
        {
          id: 5,
          img: require("../assets/imgs/gaokao_pc/h5.jpg"),
        },
        {
          id: 6,
          img: require("../assets/imgs/gaokao_pc/h1.jpg"),
        },
      ],
      verify: "获取验证码", //获取验证码
      count_down: 60, //验证倒计时
      is_click: true, //获取验证码是否禁用
      form: {
        userName: "", //姓名
        message: "", //文理科
        userPhone: "", //联系电话
        code: "", //验证码
        comeFrom: "高考冲刺PC端",
      },
      phoneRule: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/,
      // 底部环境模块轮播图
      bar: {
        width: "0",
        transform: "translateX(0)",
      },
      current: 0,
      noLast: true,
      timer: null,
      //反馈模块轮播图
      step: 0,
      transition: "none",
      marfinLeft: "0",
    };
  },
  onLoad() {
    this.autoSwitch();
  },
  computed: {
    ulWidth() {
      this.bar = {
        transform: "translateX(-" + (this.current + 1) * 100 + "%)",
      };
      return this.bar;
    },
    liWidth() {
      return 100;
    },
  },
  mounted() {
    new Swiper(".banner", {
      loop: true,
      speed: 300,
      observer: true,
      grabCursor: true,
      updateOnWindowResize: true,
      autoplay: true,
    });
  },
  methods: {
    //去首页
    toIndex() {
      location.href = "http://www.wzclxx.com/";
    },
    //选项卡点击事件
    onClickItem(option, index) {
      //改变当前选项
      this.currentIndex = index;
      //赋值
      this.form.message = option.title;
    },
    //联系我们
    contact() {
      location.href = "https://tb.53kf.com/code/client/10179248/1";
    },
    //页面验证计时器
    timerReg() {
      let that = this;
      that.is_click = false;
      var timer = setInterval(function () {
        that.verify = that.count_down-- + " S";
        if (that.count_down < -1) {
          clearInterval(timer);
          that.verify = "获取验证码";
          that.count_down = 60;
          that.is_click = true;
          return;
        }
      }, 1000);
    },
    //获取验证码
    get_code() {
      //1.手机号验证
      if (!this.phoneRule.test(this.form.userPhone)) {
        this.$message({
          type: "warning",
          message: "手机号错误，请重新填写",
        });
        return;
      }
      //2.开启页面验证定时器
      this.timerReg();
      //3.获取验证码
      this.getCode();
    },
    //获取验证码
    getCode() {
      sendSms({
        phone: this.form.userPhone,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: res.data.message,
          });
        }
        if (!res.data.data) {
          this.$message({
            type: "warning",
            message: res.data.message,
          });
        }
      });
    },
    //表单提交
     submit() {
      if (this.form.userName == "") {
        this.$message({
          type: "warning",
          message: "姓名不能为空",
        });
        return;
      }
      if (this.form.userPhone == "") {
        this.$message({
          type: "warning",
          message: "联系电话不能为空",
        });

        return;
      }
      if (this.form.message == "") {
        this.$message({
          type: "warning",
          message: "请选择文理科",
        });
        return;
      }
      if (!this.phoneRule.test(this.form.userPhone)) {
        this.$message({
          type: "warning",
          message: "手机号错误，请重新填写",
        });
        return;
      }
      submitUserInfo(this.form).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "获取成功！",
          });
           //重置
          this.form = {
            userName: "", //姓名
            message: "", //文理科
            userPhone: "", //联系电话
            code: "", //验证码
            comeFrom: "高考冲刺PC端",
          };
        } else {
          this.$message({
            type: "warning",
            message: "获取失败",
          });
        }
      });
    },
    //清空表单数据
    clear() {
      this.count_down = 60;
      this.is_click = true;
      this.from.userName = "";
      this.from.message = "";
      this.from.userPhone = "";
      this.from.code = "";
      this.form.comeFrom = "";
    },
    //高考中心学习环境轮播图模块
    //点击左侧按钮按钮事件
    prev() {
      this.current--;
      if (this.current == -1) {
        setTimeout(() => {
          this.noLast = false;
          this.current = this.list5.length - 3;
        }, 500); //定时器的时间等于过渡时间
      }
      this.noLast = true;
    },
    next() {
      this.current++;
      if (this.current == this.list5.length - 2) {
        setTimeout(() => {
          this.noLast = false;
          this.current = 0;
        }, 500);
      }
      this.noLast = true;
    },
    //自动播放
    autoSwitch() {
      this.timer = setInterval(() => {
        this.next();
      }, 2000);
    },
    //鼠标移入，暂停轮播
    over() {
      clearInterval(this.timer);
    },
    //鼠标移出，继续轮播
    out() {
      this.autoSwitch();
    },
    //反馈模块轮播
    //点击右侧按钮按钮事件
    next1() {
      this.step += 400;
      if (this.step >= 5 * 400) {
        this.step = 0;
        this.marfinLeft = "0";
        this.transition = "none";
      } else {
        this.marfinLeft = "-" + this.step;
        this.transition = "all 2.5s";
      }
    },
    //点击左侧按钮按钮事件
    prev1() {
      if (this.step != 0) {
        this.step -= 400;
        if (this.step >= 0) {
          this.marfinLeft = "-" + this.step;
          this.transition = "all 2.5s";
        }
      } else {
        this.step = 4 * 400;
        this.marfinLeft = "-" + this.step;
        this.transition = "none";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  font-size: 14px;
}
.page {
  width: 100%;
}

.header {
  width: 100%;

  img {
    width: 100%;
    display: block;
  }
}

/* 高考冲刺为梦想而战图片 */
.content1 {
  width: 100%;

  img {
    width: 100%;
    display: block;
  }
}

/* 立即咨询表单 */
.content2 {
  width: 100%;
  background: #fcfcfc;
  padding: 60px 0;
}

.content2-main {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.content2-left {
  width: 580px;
  overflow: hidden;

  img {
    width: 100%;
    transition: all 0.5s;
  }
}

.content2-left:hover {
  img {
    transform: scale(1.02);
  }
}

/* 表单 */
.content2-right {
  width: 620px;
  height: 695px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-left: 0px;
  border-radius: 0px 10px 10px 0px;
  padding: 0 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content2-right-title {
  line-height: 160px;
  text-align: center;
  font-weight: bold;
  color: #626262;
  font-size: 28px;
}

.content2-right-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  width: 100%;
  align-items: center;
  position: relative;
}

.content2-right-item span {
  width: 20%;
  color: #666666;
  font-size: 14px;
}

.content2-right-item input {
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  width: 80%;
  height: 50px;
  padding-left: 20px;
  font-size: 14px;
  box-sizing: border-box;
  outline: none;
  position: relative;
}

/* 文理科 */
.options {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.common {
  width: 45%;
  height: 50px;
  background: #efefef;
  text-align: center;
  line-height: 50px;
  color: #000000;
  border-radius: 5px;
  font-size: 14px;
}

.code {
  position: absolute;
  width: 70px;
  right: 0px;
  text-align: right;
  border-left: 1px dashed #efefef;
  font-size: 14px;
}

/* 立即咨询 */
.button {
  width: 250px;
  height: 50px;
  border-radius: 5px;
  text-align: center;
  line-height: 50px;
  color: #ffffff;
  border: none;
  margin-left: 20%;
  font-size: 16px;
  background: #fc3a30;
  margin-top: 15px;
  outline: none;
}

/* 提示 */
.tip {
  font-size: 14px;
  color: #666666;
  text-align: center;
  margin-top: 30px;
}

/* 为什么一定要上大学图片 */
.content3 {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;

  img {
    width: 100%;
    display: block;
  }

  .contact {
    position: absolute;
    width: 357px;
    height: 59.5px;
    left: 50%;
    margin-left: -178.5px;
    background: url(~@/assets/imgs/gaokao_pc/p6.png) no-repeat;
    background-size: 357px 59.5px;
    bottom: 5%;
  }
}

/* 高考为什么只选择望子成龙 */
.content4 {
  width: 100%;

  img {
    width: 100%;
  }
}

/* 历届学子风采轮播图 */
.content5 {
  width: 100%;
  height: 450px;

  swiper-item {
    width: 100%;
    height: 450px;

    img {
      width: 100%;
    }
  }
}

/* 把握高考命脉 */
.content6 {
  width: 100%;
  background: url(~@/assets/imgs/gaokao_pc/p13.png) no-repeat;
  background-size: 100% 100%;

  img {
    width: 100%;
  }
}

.content6-top {
  width: 100%;

  img {
    width: 100%;
  }
}

.content6-cen {
  width: 980px;
  height: 163px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

.content6-cen-item {
  width: 311px;
  height: 163px;
  margin-right: 30px;
  border-radius: 5px;
  background: #ffffff;
  position: relative;
  overflow: hidden;
}

.content6-cen-item:last-child {
  margin-right: 0;
}

.content6-cen-item .img {
  width: 100%;

  img {
    width: 100%;
  }
}

.content6-cen-itemt {
  position: absolute;
  width: 311px;
  height: 163px;
  top: 0;
  transform: translateY(120px);
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.5s;
}

.content6-cen-itemt-tit {
  width: 100%;
  height: 50px;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  line-height: 50px;
}

.content6-cen-itemt-con {
  padding: 20px 50px;
  color: #ffffff;
  line-height: 30px;
  font-size: 14px;
}

.content6-cen-item:hover .content6-cen-itemt {
  transform: translateY(0px);
}

.content6-bot {
  display: flex;
  flex-direction: row;
  width: 980px;
  height: 81px;
  margin: 50px auto;
}

.content6-bot-item {
  width: 233px;
  margin-right: 20px;
  height: 81px;
  transition: all 0.5s;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 5px;

  img {
    width: 100%;
    transition: all 0.5s;
  }
}

.content6-bot-item:hover {
  transform: translateY(5px);
  box-shadow: 10px 10px 10px #c0c0c0;
}

.content6-bot-item:hover img {
  transform: translateY(5px);
}

.content6-bot-item-top {
  font-size: 20px;
  font-weight: bold;
}

.content6-bot-item-bot {
  font-size: 14px;
  margin-top: 10px;
}

.active {
  background: #fc3a30;
  color: #ffffff;
}

// 高分闯关秘籍
.content7 {
  width: 100%;

  img {
    display: block;
    width: 100%;
  }
}

//冲刺学习计划
.content8 {
  position: relative;
  display: flex;
  justify-content: center;
  width: 1200px;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 50px;

  img {
    width: 100%;
  }

  .img {
    width: 357px;
    height: auto;
    background: url(~@/assets/imgs/gaokao_pc/p24.png) no-repeat;
    background-size: 100% 100%;
    margin: 5% auto 5%;
  }
}

//学生情况反馈
.content9 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: url(~@/assets/imgs/gaokao_pc/p25.jpg) no-repeat;
  background-size: 100% 100%;

  .content9-top {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .content9-cen {
    width: 1370px;
    margin: 0 auto;
    display: flex;

    flex-direction: row;

    align-items: center;

    .content9-cen-left {
      width: 85px;

      img {
        width: 50px;
      }
    }

    .content9-cen-main {
      width: 1200px;
      display: flex;
      flex-direction: row;
      overflow: hidden;

      .content9-cen-main-center {
        width: 100%;
        display: flex;
        flex-direction: row;

        .content9-cen-main-item {
          width: 370px;
          margin: 0 15px;

          img {
            width: 370px;
          }
        }
      }
    }

    .content9-cen-right {
      display: flex;
      flex-direction: row-reverse;
      width: 85px;

      img {
        width: 50px;
      }
    }
  }

  .content9-bot {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3%;
    margin-top: 5%;

    img {
      width: 357px;
      height: auto;
    }
  }
}

//精选高考教材
.content10 {
  .content10-top,
  .content10-bot {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .content10-bott {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 5%;

    img {
      width: 357px;
      height: 59.5px;
    }
  }

  .content10-cen {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .content10-cen-item {
      width: 292.5px;
      height: 180px;
      height: auto;
      overflow: hidden;
      margin-right: 10px;
      margin-bottom: 13px;

      img {
        width: 100%;
        transition: all 0.5s;
      }
    }

    .content10-cen-item:nth-child(4n) {
      margin-right: 0px;
    }

    .content10-cen-item:hover img {
      transform: scale(1.1);
    }
  }
}

//优秀学员励志奖学金
.content11 {
  width: 100%;

  img {
    width: 100%;
  }
}

//环境
.content12 {
  width: 100%;

  .content12-top {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .content12-cen {
    width: 1360px;
    display: flex;
    flex-direction: row;
    margin: 0 auto;

    .content12-cen-left,
    .content12-cen-right {
      width: 80px;
      display: flex;
      align-items: center;

      img {
        width: 50px;
      }
    }

    .content12-cen-right {
      flex-direction: row-reverse;
    }

    .content12-cen-main {
      width: 1200px;
      overflow: hidden;
      position: relative;

      .content12-cen-main-item {
        display: -webkit-box;

        .content12-cen-main-itemt {
          width: 1200px;

          img {
            width: 100%;
          }
        }
      }

      .tran {
        transition: all 0.5s;
      }
    }
  }

  .content12-bot {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    margin-top: 50px;

    img {
      width: 357px;
      height: auto;
    }
  }
}

// 尾部
.active {
  background: #fc3a30;
  color: #ffffff;
}

.foot {
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  box-sizing: border-box;

  .foot-center {
    width: 1200px;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    justify-content: space-between;

    .foot-left {
      width: 285px;
      margin: 40px 0px 60px;

      img {
        width: 100%;
      }
    }

    //表单
    .foot-main {
      margin: 40px 0px 60px;
      background: #ffffff;
      border-left: 0px;
      padding: 30px 80px;
      box-sizing: border-box;
      width: 600px;
      border-radius: 5px;

      .foot-main-title {
        line-height: 80px;
        text-align: center;
        font-weight: bold;
        color: #626262;
        font-size: 24px;
      }

      .foot-main-item {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        width: 100%;
        align-items: center;
        position: relative;

        span {
          width: 20%;
          color: #666666;
          font-size: 14px;
        }

        input {
          border: 1px solid #e9e9e9;
          border-radius: 5px;
          width: 80%;
          height: 40px;
          padding-left: 20px;
          font-size: 14px;
          box-sizing: border-box;
          outline: none;
        }

        .options {
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .common {
            width: 45%;
            height: 40px;
            background: #efefef;
            text-align: center;
            line-height: 40px;
            color: #000000;
            border-radius: 5px;
            font-size: 14px;
          }

          .active {
            background: #fc3a30;
            color: #ffffff;
          }
        }

        .code {
          position: absolute;
          width: 100px;
          right: 0px;
          top: -46px;
          text-align: center;
          border-left: 1px dashed #efefef;
          font-size: 14px;
        }
      }

      .foot-button {
        width: 250px;
        height: 40px;
        border-radius: 5px;
        text-align: center;
        line-height: 40px;
        color: #ffffff;
        border: none;
        margin-left: 20%;
        font-size: 16px;
        background: #fc3a30;
        margin-top: 25px;
        outline: none;
      }

      .tip {
        font-size: 12px;
        color: #666666;
        text-align: center;
        margin-top: 15px;
      }
    }
  }
}

.cursor {
  cursor: pointer;
}
</style>
