import { render, staticRenderFns } from "./gaokao_pc.vue?vue&type=template&id=207c561d&scoped=true&"
import script from "./gaokao_pc.vue?vue&type=script&lang=js&"
export * from "./gaokao_pc.vue?vue&type=script&lang=js&"
import style0 from "./gaokao_pc.vue?vue&type=style&index=0&id=207c561d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "207c561d",
  null
  
)

export default component.exports